body {
  font-family: $body-font;
  font-weight: 400;
  background-color: $secondary;
  color: $white;
  line-height: 30px;
  letter-spacing: 0.5px;
  box-sizing: border-box;
  word-break: break-word;
  background-image: url(../../assets/images/bg-texture.webp);
  #root {
    display: flex;
    flex-direction: column;
    position: relative;
    min-height: 100vh;
    overflow-x: hidden;
  }
}
.block-scroll {
  overflow: hidden;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: $heading-font;
  font-weight: 500;
  line-height: 1.2;
}

a {
  color: inherit;
  text-decoration: none;
}

button {
  border: 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

address {
  font-style: normal;
}

main {
  width: 80%;
  max-width: 1100px;
  margin-inline: auto;
  @media #{$sm-layout} {
    width: 70%;
  }
  .page-title {
    position: relative;
    font-weight: 700;
    font-size: 36px;
    &::before {
      @include underline-before($white);
      width: 100%;
      bottom: -10px;
    }
    @media #{$lg-layout} {
      font-size: 28px;
    }
  }
  section {
    display: flex;
    padding-block: 50px;
    @media #{$md-layout} {
      padding-block: 25px;
    }
    h2 {
      @media #{$lg-layout} {
        font-size: 20px;
      }
    }
  }
}
