.remy-portfolio {
  .project-grid {
    display: grid;
    grid: auto / 1fr 1fr;
    gap: 50px;
    @media #{$md-layout} {
      grid: auto / 1fr;
    }
    .project-card {
      position: relative;
      height: 250px;
      &:hover {
        user-select: none;
        .btn-card {
          transform: translateX(-25px);
        }
        .darken-background {
          opacity: 0.2;
        }
        .card-title {
          opacity: 0;
        }
        .btn {
          &.btn-card {
            background-color: $secondary;
          }
        }
      }
      .card-title {
        position: relative;
        margin-left: 80px;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        z-index: 2;
        transition: $transi;
        @media #{$lg-layout} {
          margin-left: 40px;
        }
        @media #{$md-layout} {
          margin-left: 20px;
        }
        h2 {
          margin-top: 16px;
        }
      }
      .btn {
        position: absolute;
        bottom: 10px;
        right: 10px;
        font-size: 20px;
        z-index: 2;
        @media #{$md-layout} {
          font-size: 16px;
        }
        &.btn-card {
          height: auto;
          width: max-content;
          border: none;
          color: $primary;
          background-color: transparent;
          padding: 5px 15px;
          line-height: normal;
          &:hover {
            background-color: $white;
            color: $secondary;
          }
        }
      }
      .darken-background {
        position: absolute;
        inset: 0;
        background-color: $secondary-light;
        opacity: 1;
        transition: $transi;
      }
    }
  }
  .tag-list {
    display: flex;
    align-items: center;
    margin: 0;
    gap: 10px;
    color: $gray;
    li {
      padding-right: 10px;
      &:not(:last-child) {
        border-right: 1px solid $gray;
      }
    }
  }
  .project-modal {
    width: 80%;
    max-width: 1100px;
    background-color: $secondary;
    border: 1px solid $primary;
    &[open] {
      display: flex;
      flex-direction: column;
    }
    .modal-content {
      display: flex;
      flex-basis: 50%;
      flex-wrap: wrap;
      justify-content: space-between;
      padding: 15px;
      gap: 30px;
      @media #{$sm-layout} {
        flex-direction: column;
      }
      > * {
        width: 45%;
        height: 400px;
        @media #{$md-layout} {
          width: 100%;
        }
      }
      .description {
        color: $gray;
        height: max-content;
        h2 {
          color: $white;
        }
        .modal-btn {
          display: flex;
          flex-wrap: wrap;
          height: 50px;
          gap: 20px;
          margin-top: 30px;
          .btn {
            height: 100%;
            width: max-content;
            padding: 10px 20px;
            font-size: 18px;
            @media #{$lg-layout} {
              font-size: 16px;
            }
          }
        }
      }
      img {
        display: flex;
        justify-content: center;
        align-items: center;
        object-fit: cover;
        transition: $transi;
        border-radius: 10px;
        &:first-of-type {
          order: -1;
          @media #{$md-layout} {
            order: unset;
          }
        }
        &:before {
          color: $white;
        }
      }
    }
    .close-modal {
      display: flex;
      width: max-content;
      align-items: center;
      align-self: flex-end;
      background-color: transparent;
      color: $white;
      font-size: 28px;
      cursor: pointer;
      border: none;
    }
    &::backdrop {
      background-color: $gray-15;
    }
  }
}
