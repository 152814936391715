.remy-contact {
  section.contact {
    flex-direction: column;
    .contact-form {
      width: 70%;
      @media #{$lg-layout} {
        width: 80%;
      }
      @media #{$md-layout} {
        width: 100%;
      }
    }
    .btn {
      width: 150px;
      height: 50px;
      font-size: 16px;
    }
  }
}
