.remy-header {
  height: 100px;
  @media #{$sm-layout} {
    height: 70px;
  }
  .nav-link {
    font-weight: 700;
    position: relative;
    &:focus {
      outline: none;
    }
    &::before {
      @include underline-before($white);
    }
    &:hover::before,
    &:focus::before,
    &.active::before {
      width: 100%;
    }
    .link-icon {
      font-size: 18px;
      display: none;
      align-items: center;
      @media #{$lg-layout} {
        display: flex;
      }
    }
  }
  nav {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    ul {
      display: flex;
      padding: 30px 50px;
      gap: 20px;
      margin: 0;
      @media #{$lg-layout} {
        width: 100%;
        justify-content: space-evenly;
        .link-icon {
          display: flex;
        }
      }
      .nav-link {
        @media #{$sm-layout} {
          display: none;
        }
      }
      li a {
        display: flex;
        gap: 10px;
      }
    }
    .burger-menu {
      display: none;
      font-size: 68px;
      margin-right: 20px;
      cursor: pointer;
      z-index: 99;
      @media #{$sm-layout} {
        display: block;
      }
    }
  }

  #side-menu-inner {
    display: none;
    width: 200px;
    height: 100vh;
    background-color: $secondary;
    border: 0 solid $primary;
    border-left-width: 1px;
    border-bottom-width: 1px;
    position: absolute;
    top: 0;
    right: 0;
    z-index: 10;
    font-family: $heading-font;
    padding-top: 100px;
    transform: translateX(201px);
    transition: $transi;
    ul {
      align-self: flex-end;
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: flex-end;
      margin-right: 20px;
      width: max-content;
      li {
        width: 100%;
        a {
          display: flex;
          justify-content: space-between;
          gap: 10px;
        }
      }
    }
    @media #{$sm-layout} {
      display: flex;
      flex-direction: column;
    }
  }
  .side-menu {
    position: absolute;
    inset: 0;
    &.open {
      #side-menu-inner {
        transform: translateX(0);
      }
    }
  }
}
