.contact-form {
  display: flex;
  flex-direction: column;
  gap: 30px;
  @media #{$sm-layout} {
    gap: 15px;
  }
  label {
    color: $primary;
    display: flex;
    flex-direction: column;
    input,
    textarea {
      height: 40px;
      border: 1px solid $primary;
      background-color: transparent;
      border-radius: 5px;
      color: $white;
      padding: 5px 10px;
      @media #{$sm-layout} {
        height: 40px;
      }
    }
    textarea {
      height: 150px;
      @media #{$sm-layout} {
        height: 100px;
      }
    }
  }
  .name-inputs {
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    @media #{$sm-layout} {
      gap: 15px;
    }
    label {
      width: calc(50% - 15px);
      @media #{$sm-layout} {
        width: 100%;
      }
    }
  }
  .submit-container {
    display: flex;
    gap: 30px;
    align-items: center;
    margin-top: 30px;
    @media #{$md-layout} {
      flex-direction: column-reverse;
      align-items: unset;
      gap: 15px;
    }
    @media #{$sm-layout} {
      margin-top: 15px;
    }
    label {
      flex-direction: row;
      justify-content: center;
      align-items: center;
      font-size: 20px;
      input[type="submit"] {
        border: none;
        background-color: unset;
        color: inherit;
        cursor: pointer;
        padding: 0;
        font-weight: inherit;
      }
    }
    .submit-message {
      @media #{$lg-layout} {
        font-size: 15px;
      }
      @media #{$sm-layout} {
        font-size: 13px;
      }
    }
  }
}
