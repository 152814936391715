.profile-picture {
  width: 350px;
  height: 450px;
  padding: 10px;
  background: linear-gradient(-45deg, transparent, $primary, transparent);
  border-radius: 40px;
  img {
    width: 100%;
    height: 100%;
    border-radius: 40px;
    transform: rotate(10deg);
    transition: $transi;
    object-fit: cover;
    &:hover {
      transform: rotate(0);
    }
    @media #{$sm-layout} {
      transform: none;
    }
  }
  @media #{$xl-layout} {
    width: 250px;
    height: 350px;
  }
  @media #{$md-layout} {
    width: 200px;
    height: 250px;
  }
}
